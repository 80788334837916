<template>
  <div class="sourceForm">
    <!-- <div class="top-btn">
      <v-button text="返回"></v-button>
    </div> -->
    <div class="bottom-wrap">
      <div class="title">流程介绍</div>
      <div class="bottom-main">
        <div class="nav-title" v-for="(item, index) in titleList" :key="index">
          <div class="nav-content" @click="handleClick(item)">
            <img :src="item.imgSource" alt="" />
            <span>{{ item.title }}</span>
            <div v-if="index < 4" class="arrows"></div>
          </div>
        </div>
      </div>
      <div class="title mt50">使用情况</div>
      <div class="bottom-type">
        <div class="item-type">
          <div>资产模型</div>
          <div class="item-number">{{ useStatusList.modelCnt }}</div>
          <!-- <span class="text-left">资产模型</span><span class="text">9</span> -->
        </div>
        <div class="item-type">
          <div>分析任务</div>
          <div class="item-number">{{ useStatusList.analysisCnt }}</div>
        </div>
        <div class="item-type">
          <div>存储任务</div>
          <div class="item-number">{{ useStatusList.storeCnt }}</div>
        </div>
      </div>
      <div class="bottom-echart">
        <div class="item-echart" ref="sourceEchart"></div>
        <div class="item-echart" ref="marketEchart"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSoureList } from "./api.js";
import * as echarts from "echarts";
import { debounce } from "@/utils/utils";
export default {
  data() {
    return {
      useStatusList: {
        modelCnt: "", //资产模型
        analysisCnt: "", // 分析任务
        storeCnt: "", //存储任务
      },
      titleList: [
        {
          imgSource: require("./images/access.png"),
          title: "数据源接入",
          name: "sourceData",
        },
        {
          imgSource: require("./images/store.png"),
          title: "存储管理",
          name: "storeManage",
        },
        {
          imgSource: require("./images/mode.png"),
          title: "资产建模",
          name: "modelManage",
        },
        {
          imgSource: require("./images/analysis.png"),
          title: "数据分析",
          name: "dataAnaly",
        },
        {
          imgSource: require("./images/market.png"),
          title: "数据集市",
          name: "dataMarket",
        },
      ],
      market: "",
      source: "",
    };
  },
  created() {
    this.getSourceList();
  },
  mounted() {
    window.addEventListener("resize", this.resizeHandler, true);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  methods: {
    // 获取总览页面数据
    getSourceList() {
      this.$axios.get(`${getSoureList}`).then((res) => {
        if (res.code === 200) {
          console.log("res----->", res);
          const {
            modelCnt,
            analysisCnt,
            storeCnt,
            dataMarketStaticsList,
            dataSourceStaticsList,
          } = res.data;
          this.useStatusList.modelCnt = modelCnt;
          this.useStatusList.analysisCnt = analysisCnt;
          this.useStatusList.storeCnt = storeCnt;
          const x = dataSourceStaticsList.map((item) => item.name);
          const y = dataSourceStaticsList.map((item) => item.cnt);
          const date = dataMarketStaticsList.map((item) => item.date);
          const cntList = dataMarketStaticsList.map((item) => item.cnt);
          this.initSourceEchart(x, y);
          this.initMarketEchart(date, cntList);
        }
      });
    },
    // 初始化数据接入分布图
    initSourceEchart(x, y) {
      this.source = echarts.init(this.$refs.sourceEchart);
      const option = {
        title: {
          text: "数据接入分布图",
          left: "center",
          padding: [20, 0],
        },
        grid: {},
        tooltip: {
          show: true,
        },
        xAxis: {
          type: "category",
          data: x,
          axisLabel: {
            showMaxLabel: true,
            interval: 0,
          },
        },
        yAxis: {
          type: "value",
          name: "数量",
          axisLine: {
            show: true,
          },
        },
        series: [
          {
            data: y,
            type: "bar",
            barWidth: 25,
          },
        ],
      };
      this.source.setOption(option);
    },
    // 初始化数据集市
    initMarketEchart(x, y) {
      this.market = echarts.init(this.$refs.marketEchart);
      const option = {
        title: {
          text: "数据集市资源近7天调用分布",
          left: "center",
          padding: [20, 0],
        },
        tooltip: {
          show: true,
        },
        xAxis: {
          type: "category",
          data: x,
        },
        yAxis: {
          type: "value",
          name: "次数",
          axisLine: {
            show: true,
          },
        },
        series: [
          {
            itemStyle: { normal: { label: { show: true } } },
            data: y,
            type: "line",
          },
        ],
      };
      this.market.setOption(option);
    },
    //echart图表自适应
    resizeHandler() {
      return debounce(() => {
        this.market.resize();
        this.source.resize();
      }, 100)();
    },
    handleCommand(command) {
      console.log(command);
    },
    handleClick(arg) {
      console.log(arg);
      this.$router.push({
        name: arg.name,
      });
    },
  },
};
</script>

<style lang="less" scoped>
::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.sourceForm {
  box-sizing: border-box;
  height: 100%;
  .bottom-wrap {
    height: calc(100% - 50px);
    overflow-x: hidden;
    overflow-y: auto;
    .title {
      font-size: 18px;
      font-weight: bold;
      margin-top: 50px;
    }
    .mt50 {
      margin-top: 30px;
    }
    .bottom-main {
      box-sizing: border-box;
      padding: 0 20px;
      width: 100%;
      height: 460px;
      margin-top: 36px;
      border-radius: 15px;
      border: 1px solid #dcdfe6;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      .nav-title {
        cursor: pointer;
        flex: 1;
        display: flex;
        align-items: center;
        &:nth-child(1) {
          margin-left: 100px;
        }

        .nav-content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          .arrows {
            width: 105px;
            height: 0px;
            border-bottom: 3px dashed #7fa1ff;
            right: -114px;
            top: 46%;
            position: absolute;
            // &::after {
            //   content: "";
            //   position: absolute;
            //   right: -20px;
            //   top: -8px;
            //   border-left: 20px solid #459df9;
            //   border-top: 10px solid transparent;
            //   border-bottom: 10px solid transparent;
            // }
          }
          img {
            width: 232px;
            height: 232px;
          }
          span {
            margin-top: 20px;
          }
        }
      }
    }
    .bottom-type {
      display: flex;
      justify-content: space-between;
      .item-type {
        display: flex;
        width: 25%;
        height: 90px;
        align-items: center;
        justify-content: space-around;
        border-radius: 15px;
        margin-top: 15px;
        border: 1px solid #dcdfe6;
        .item-number {
          color: #4e8fff;
        }
      }
    }
    .bottom-echart {
      display: flex;
      justify-content: space-around;
      .item-echart {
        display: flex;
        min-width: 300px;
        width: 40%;
        height: 544px;
        border-radius: 15px;
        margin-top: 30px;
        padding-left: 38px;
        border: 1px solid #dcdfe6;
      }
    }
  }
}
</style>
